import { useLazyGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useLazyGetTilesetByObjectIdQuery } from '@local/api-clients/dist/visualization/enhancedVisualizationClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import type { MetadataState, PlotState, UpdateSnapshot } from '@local/webviz/dist/types';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { toSuffixUid, ElementClass } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import isFinite from 'lodash-es/isFinite';
import merge from 'lodash-es/merge';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'src/store/store';
import { loadedObjectsMap } from 'src/store/visualization/selectors';
import { Schemas } from 'src/visualization/constants';
import {
    createProjectTreeViewSnapshot,
    createViewSnapshot,
} from 'src/visualization/context/snapshots/generateSnapshot';

import { categoryDataSnapshot } from '../../snapshots/categoryDataSnapshot';

export function useObjectLoader() {
    const {
        setStateFromSnapshot,
        addListener,
        addViewToPlotDirectly,
        removeViewsFromPlotDirectly,
        getEntityState,
    } = useBaseXyz();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [generatedSnapshot, setGeneratedSnapshot] = useState<UpdateSnapshot>({});
    const objectsToLoadMap = useAppSelector(loadedObjectsMap);
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const [getTilesetByTrigger] = useLazyGetTilesetByObjectIdQuery();

    useEffect(() => {
        const plot = getEntityState('plot') as PlotState;
        const oldPlotViews = [...plot.views];

        const snapshotPromises = Object.values(objectsToLoadMap).map(async (objectToLoad) => {
            let viewSnapshot;
            if ('object' in objectToLoad) {
                viewSnapshot = createViewSnapshot(objectToLoad, {
                    objectId: objectToLoad.object_id,
                    workspaceId,
                    orgId,
                });
                return viewSnapshot;
            }
            const parentGooseResponse = await getObjectByIdTrigger(
                {
                    objectId: objectToLoad.parentId,
                    orgId,
                    workspaceId,
                },
                true,
            ).unwrap();
            switch (objectToLoad.schema) {
                case Schemas.DownholeIntervalsSchema: {
                    try {
                        const tileset: any = await getTilesetByTrigger(
                            {
                                orgId,
                                workspaceId,
                                objectId: objectToLoad.parentId,
                            },
                            true,
                        ).unwrap();

                        viewSnapshot = createProjectTreeViewSnapshot(
                            objectToLoad,
                            parentGooseResponse,
                            tileset,
                        );
                        return viewSnapshot;
                    } catch (error: any) {
                        trackError(error);
                        return undefined;
                    }
                }
                case Schemas.PointsetSchema: {
                    viewSnapshot = createProjectTreeViewSnapshot(objectToLoad, parentGooseResponse);
                    return viewSnapshot;
                }
                default:
                    return undefined;
            }
        });

        Promise.all(snapshotPromises).then((viewSnapshots) => {
            let baseSnapshot: UpdateSnapshot = generatedSnapshot;
            const viewIds = viewSnapshots.map((viewSnapshot) => {
                if (!viewSnapshot?.snapshot) {
                    return undefined;
                }
                if (oldPlotViews.includes(viewSnapshot.viewId)) {
                    return viewSnapshot.viewId;
                }

                // Persist previous status to preserve error states of other objects
                const newSnapshot = viewSnapshot.snapshot;
                const newViewEntity = newSnapshot[viewSnapshot.viewId];
                if (newViewEntity && 'status' in newViewEntity) {
                    delete newViewEntity.status;
                }

                setStateFromSnapshot(newSnapshot, {});
                addViewToPlotDirectly(viewSnapshot.viewId);
                baseSnapshot = { ...baseSnapshot, ...newSnapshot };
                return viewSnapshot.viewId;
            });
            const viewIdsToRemove = oldPlotViews.filter(
                (oldViewId) => !viewIds.includes(oldViewId),
            );
            removeViewsFromPlotDirectly(viewIdsToRemove);
            setGeneratedSnapshot(baseSnapshot);
        });
    }, [objectsToLoadMap]);

    useEffect(() => {
        const listeners: (() => void)[] = [];
        Object.keys(generatedSnapshot).forEach((elementId) => {
            if (!elementId.includes(ElementClass.Tileset3D)) return;
            const removeElementMetadataListener = addListener(
                elementId,
                'metadata',
                async (attributes) => {
                    if (!attributes?.attributes_metadata) return;
                    let { attributes_metadata: attributesMetadata } = attributes;

                    const downloadMetadataRequests: Promise<void>[] = [];
                    const downloadedMetadata: MetadataState = {};
                    const attributeIds = Object.keys(attributesMetadata);
                    attributeIds.forEach((attributeId) => {
                        if ('uri' in attributesMetadata[attributeId]) {
                            const { uri } = attributesMetadata[attributeId];
                            downloadMetadataRequests.push(
                                fetch(uri)
                                    .then(async (response) => {
                                        if (!response.ok) {
                                            throw new Error(
                                                `Failed to download attribute ${attributeId} with status code ${response.status}`,
                                            );
                                        }
                                        const metadata = await response.json();
                                        downloadedMetadata[attributeId] = metadata;
                                        delete attributesMetadata[attributeId].uri;
                                    })
                                    .catch((error) => trackError(error)),
                            );
                        }
                    });
                    await Promise.all(downloadMetadataRequests);
                    attributesMetadata = merge(attributesMetadata, downloadedMetadata);
                    const attributesSnapshot = attributeIds.reduce(
                        (accumulator: UpdateSnapshot, attributeId: string) => {
                            if ('metadata' in attributesMetadata[attributeId]) {
                                const { metadata } = attributesMetadata[attributeId];
                                if ('min' in metadata && 'max' in metadata) {
                                    const { min, max } = metadata;
                                    const mappingId = toSuffixUid(
                                        attributeId,
                                        UID_SUFFIXES.MAPPING,
                                    );
                                    const minValue = isFinite(min) ? min : -Infinity;
                                    const maxValue = isFinite(max) ? max : +Infinity;

                                    const mappingEntity = getEntityState(mappingId);

                                    if (
                                        mappingEntity &&
                                        'data_control_values' in mappingEntity &&
                                        mappingEntity.data_control_values.every((value) =>
                                            isFinite(value),
                                        )
                                    ) {
                                        return { ...accumulator };
                                    }

                                    return {
                                        ...accumulator,
                                        [mappingId]: {
                                            data_control_values: [
                                                minValue,
                                                minValue,
                                                maxValue,
                                                maxValue,
                                            ],
                                        },
                                    };
                                }

                                if ('lookup_table' in metadata) {
                                    const { lookup_table: lookupTable } = metadata;
                                    return {
                                        ...accumulator,
                                        ...categoryDataSnapshot(attributeId, lookupTable),
                                    };
                                }
                            }
                            return { ...accumulator };
                        },
                        {},
                    );
                    setStateFromSnapshot(attributesSnapshot, {});
                },
            );
            listeners.push(removeElementMetadataListener);
        });

        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, [generatedSnapshot]);
}
