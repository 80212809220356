import { GlobeIcon } from '@local/web-design-system/dist/icons/Map/GlobeIcon';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';

import { GLOBAL_MAP, RESET_ZOOM } from 'src/strings';

import { useStyles } from './Control.styles';
import { MapControlTooltip } from './MapControlsTooltip';

export function ResetZoomButton() {
    const { classes } = useStyles();
    const [disabled, setDisabled] = useState(false);

    const map = useMapEvents({
        zoomend: () => {
            setDisabled(map.getZoom() === map.getMinZoom());
        },
        zoomlevelschange: () => {
            setDisabled(map.getZoom() === map.getMinZoom());
        },
    });

    const onResetZoom = () => {
        if (!disabled) {
            map.setZoom(map.getMinZoom());
        }
    };

    useEffect(() => {
        setDisabled(map.getZoom() === map.getMinZoom());
    }, [map]);

    return (
        <MapControlTooltip title={GLOBAL_MAP} placement="left">
            <IconButton
                title={RESET_ZOOM}
                disabled={disabled}
                onClick={onResetZoom}
                aria-label={RESET_ZOOM}
                aria-disabled={disabled}
                className={classes.controlButton}
                automation-id="zoom-control-reset-zoom"
                sx={{
                    '&.Mui-disabled': {
                        color: '#39393C',
                        backgroundColor: '#1D1F23',
                    },
                }}
            >
                <GlobeIcon
                    className={classNames(classes.controlIcon, {
                        [classes.iconDisabled]: disabled,
                    })}
                />
            </IconButton>
        </MapControlTooltip>
    );
}
