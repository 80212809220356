import {
    GetObjectResponse,
    useLazyGetObjectByIdQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import { loadedObjectsMap, getTreeItemById } from 'src/store/visualization/selectors';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { Schemas } from 'src/visualization/constants';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId = '') {
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const dispatch = useAppDispatch();
    const [
        getObjectByIdTrigger,
        { isLoading: isNetworkLoading, isSuccess, isError: isNetworkError },
    ] = useLazyGetObjectByIdQuery();
    const treeItem = useAppSelector(getTreeItemById(listItemId));
    const parentTreeItem = useAppSelector(getTreeItemById(treeItem?.parentId));
    async function loadObject(objectId: string) {
        if (isObjectLoaded(objectId)) {
            if (isError) {
                const gooseObject = loadedObjects[objectId];
                dispatch(removeFromLoadedObjects(objectId));

                const { object } = gooseObject as GetObjectResponse;
                const viewId = getViewIdFromObjectId(
                    objectId,
                    `${treeItem.schema ?? object.schema}`,
                );
                disposeEntity(viewId);
            } else {
                return;
            }
        }
        setIsError(false);
        setIsLoading(true);
        if (parentTreeItem?.schema === Schemas.DownholeCollectionSchema) {
            dispatch(addToLoadedObjects(treeItem));
        } else {
            try {
                const gooseObject = await getObjectByIdTrigger({
                    objectId,
                    orgId,
                    workspaceId,
                }).unwrap();
                dispatch(addToLoadedObjects(gooseObject));
            } catch (error) {
                trackError('Error loading goose object for visualization', JSON.stringify(error));
            }
        }
    }

    const loadedObjects = useAppSelector(loadedObjectsMap);
    function isObjectLoaded(objectId: string) {
        return objectId in loadedObjects;
    }

    const [isLoading, setIsLoading] = useState(isNetworkLoading);
    const [isError, setIsError] = useState(isNetworkLoading);
    useEffect(() => {
        if (isNetworkLoading) {
            setIsLoading(true);
            setIsError(false);
        } else if (isNetworkError) {
            setIsLoading(false);
            setIsError(true);
        } else if (!isObjectLoaded(listItemId)) {
            setIsLoading(false);
            setIsError(false);
        }
    }, [isNetworkLoading, isNetworkError, loadedObjects]);

    const { useXyzListener, addViewStatusListener, disposeEntity } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });
    useEffect(() => {
        const objectViewId = views.find((view) => view.startsWith(listItemId));
        if (!objectViewId) return () => {};

        const removeViewStatusListener = addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                setIsLoading(false);
                setIsError(false);
                removeViewStatusListener();
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                setIsError(true);
            },
            onPending: () => {
                setIsLoading(true);
                setIsError(false);
            },
        });
        return () => {
            removeViewStatusListener();
        };
    }, [views]);

    return {
        loadObject,
        isError,
        isLoading,
        isSuccess,
        isObjectLoaded,
    };
}
