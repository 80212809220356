import { ZoomOutIcon } from '@local/web-design-system/dist/icons/Map/ZoomOutIcon';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';

import { ZOOM_OUT } from 'src/strings';

import { useStyles } from './Control.styles';

export function ZoomOutButton() {
    const { classes } = useStyles();
    const [disabled, setDisabled] = useState(false);

    const map = useMapEvents({
        zoomend: () => {
            setDisabled(map.getZoom() === map.getMinZoom());
        },
        zoomlevelschange: () => {
            setDisabled(map.getZoom() === map.getMinZoom());
        },
    });

    const onZoomOut = (event: any) => {
        if (!disabled && map?.options.zoomDelta !== undefined && map.getZoom() > map.getMinZoom()) {
            map.zoomOut(map.options.zoomDelta * (event.shiftKey ? 3 : 1));
        }
    };

    useEffect(() => {
        setDisabled(map.getZoom() === map.getMinZoom());
    }, [map]);

    return (
        <IconButton
            title={ZOOM_OUT}
            onClick={onZoomOut}
            disabled={disabled}
            aria-label={ZOOM_OUT}
            aria-disabled={disabled}
            className={classes.controlButton}
            automation-id="zoom-control-zoom-out"
            sx={{
                '&.Mui-disabled': {
                    color: '#39393C',
                    backgroundColor: '#1D1F23',
                },
            }}
        >
            <ZoomOutIcon
                className={classNames(classes.controlIcon, { [classes.iconDisabled]: disabled })}
            />
        </IconButton>
    );
}
